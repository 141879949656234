import Hls from "hls.js";
import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import focusLock from "dom-focus-lock";
import { timestampToFormattedTime, getCookie, setCookie } from "../utilities";

export default class extends Controller {
  static targets = [
    "audio",
    "autoplayNotification",
    "autoplayNotificationCountdown",
    "autoplayNotificationImage",
    "autoplayNotificationLabel",
    "autoplayNotificationProgressBar",
    "autoplayNotificationTitle",
    "bannerNotification",
    "skippedSectionNotification",
    "skippedSectionNotificationCountdown",
    "skippedSectionNotificationImage",
    "skippedSectionNotificationLabel",
    "skippedSectionNotificationProgressBar",
    "skippedSectionNotificationTitle",
    "currentTime",
    "duration",
    "forwardButton",
    "nextButton",
    "nextTrackTitle",
    "poster",
    "bookPoster",
    "playPauseButton",
    "previousButton",
    "previousTrackTitle",
    "progress",
    "rewindButton",
    "section",
    "styles",
    "title",
    "tracklist",
    "tracklistButton",
    "tracklistItem",
    "transcript",
    "transcriptButton",
    "transcriptLink",
    "video",
  ];
  static values = {
    autoplayNotification: Boolean,
    disabled: Boolean,
    loading: Boolean,
    poster: Boolean,
    sections: Array,
    skippedSectionNotification: Boolean,
    track: Number,
    tracklist: Array,
    tracklistVisible: Boolean,
    transcript: Boolean,

    // States that should only be updated by the audio player itself
    currentTime: Number,
    duration: Number,
    paused: Boolean,
  };

  /*
  :::::::::::::::
  :: Lifecycle ::
  :::::::::::::::
  */

  initialize() {
    // if (Hls.isSupported()) {
    //   this.hls = new Hls();
    //   this.hls.attachMedia(this.videoTarget);
    //   this.player = this.videoTarget;
    // } else if (this.videoTarget.canPlayType("application/vnd.apple.mpegurl")) {
    //   this.player = this.videoTarget;
    // } else {
    //   this.player = this.audioTarget;
    // }
      this.player = this.audioTarget;

    window.audioPlayer = this;
  }

  connect() {
    window.audioPlayer = this;
  }

  /*
  ::::::::::::::::::::
  :: Event Handlers ::
  ::::::::::::::::::::
  */

  handlePlayerPlay() {
    this.pausedValue = false;
  }

  handlePlayerPause() {
    this.pausedValue = true;
  }

  /*
  ::::::::::::::::::::::::::
  :: Custom Value Getters ::
  ::::::::::::::::::::::::::
  */

  get currentTrack() {
    if (this.trackValue === null) {
      return null;
    }

    return this.tracklistValue[this.trackValue];
  }

  get currentSection() {
    const { currentSectionIndex } = this;
    if (typeof currentSectionIndex !== "number") {
      return null;
    }

    return this.sectionsValue[currentSectionIndex];
  }

  get currentSectionIndex() {
    if (!this.currentTrack) {
      return null;
    }

    const { sectionId } = this.currentTrack;
    return this.sectionsValue.findIndex(({ id }) => id == sectionId);
  }

  get nextTrack() {
    if (this.nextTrackIndex === null) {
      return null;
    }

    return this.tracklistValue[this.nextTrackIndex];
  }

  get nextTrackIndex() {
    if (this.trackValue === null) {
      return null;
    } else if (this.trackValue === this.tracklistValue.length - 1) {
      return null;
    } else {
      return this.trackValue + 1;
    }
  }

  // NOTE: not necessarily the section of `this.nextTrack`
  get nextSection() {
    if (typeof this.nextSectionIndex !== "number") {
      return null;
    }

    return this.sectionsValue[this.nextSectionIndex];
  }

  get nextSectionIndex() {
    const { currentSectionIndex } = this;
    if (currentSectionIndex < this.sectionsValue.length - 1) {
      return currentSectionIndex + 1;
    }

    return null;
  }

  get previousTrack() {
    if (this.previousTrackIndex === null) {
      return null;
    }

    return this.tracklistValue[this.previousTrackIndex];
  }

  get previousTrackIndex() {
    if (this.trackValue === null) {
      return null;
    } else if (this.trackValue === 0) {
      return null;
    } else {
      return this.trackValue - 1;
    }
  }

  /*
  ::::::::::::::::::::
  :: Public methods ::
  ::::::::::::::::::::
  */

  cancelAutoplay() {
    const animations =
      this.autoplayNotificationProgressBarTarget.getAnimations();
    animations.forEach((animation) => animation.pause());
  }

  countDownToAutoplay() {
    if (this.nextTrack === null) {
      return;
    }

    this.autoplayNotificationValue = true;
  }

  dismissAutoplayNotification() {
    this.cancelAutoplay();
    this.autoplayNotificationValue = false;
  }

  dismissSkippedSectionNotification() {
    this.skippedSectionNotificationValue = false;
  }

  dismissBannerNotification() {
    this.bannerNotificationTarget.classList.add("hidden");
    setCookie("banner_dismissed", true, 1);
  }

  forward() {
    this.player.currentTime += 15;
  }

  hideTracklist() {
    this.tracklistVisibleValue = false;
  }

  hideTranscript() {
    // NOTE: the transcript will never be visible on desktop, so this action should just bail out.
    // TODO: use real breakpoint
    if (window.innerWidth >= 648) {
      return;
    }

    this.transcriptValue = false;
  }

  next(event) {
    if (this.nextTrackIndex === null) {
      return;
    }

    this.playTrack(this.nextTrackIndex);
    this.visitPageForTrack();
  }

  notifyAboutSkippedSection(event) {
    if (
      parseInt(this.currentTrack.sectionPosition, 10) + 1 ==
      parseInt(this.nextTrack.sectionPosition, 10)
    ) {
      return;
    }

    this.skippedSectionNotificationValue = true;
  }

  pause() {
    this.dispatch("before-pause");
    this.player.pause();
    this.dispatch("pause");
  }

  play() {
    // If the connection is fast, the loading icon would flash for a moment.
    // To prevent this, we delay it with a cancelable delay
    const delayBeforeShowingLoadingIcon = setTimeout(() => {
      this.loadingValue = true;
    }, 500);
    this.posterValue = false;

    // If a banner notification exists and the user hasn't dismissed it,
    // dismiss it now.
    if (this.hasBannerNotificationTarget && !getCookie("banner_dismissed")) {
      this.dismissBannerNotification();
    }

    this.dispatch("before-play");
    return this.player.play().then(() => {
      clearTimeout(delayBeforeShowingLoadingIcon);
      this.loadingValue = false;
      this.dispatch("play", { detail: { track: this.trackValue } });
    });

  }

  playTrack(index, ifAlreadyPlaying = "resume") {
    const track = this.tracklistValue[index];

    // If there's no track to play...
    if (!track) {
      return;
    }

    // If the player is already set to this track, we might have already
    // missed the `MEDIA_ATTACHED` event, so we should just play it.
    if (this.trackValue === index) {
      if (ifAlreadyPlaying === "restart") {
        this.currentTimeValue = 0;
      }
      this.play();
      return;
    }

    this.currentTimeValue = 0;
    this.autoplayNotificationValue = false;
    this.skippedSectionNotificationValue = false;
    this.trackValue = index;
    this.recordDimensions();

    // if (Hls.isSupported()) {
    //   // When the media is attached (i.e. when HLS.js has recognized the new track), play it
    //   const handler = () => {
    //     this.play();
    //     this.hls.off(Hls.Events.MEDIA_ATTACHED, handler);
    //   };

    //   this.hls.on(Hls.Events.MEDIA_ATTACHED, handler);
    // } else {
    //   const handler = () => {
    //     this.play();
    //     this.player.removeEventListener("loadedmetadata", handler);
    //   };
    //   this.player.addEventListener("loadedmetadata", handler);
    // }
    const handler = () => {
      this.play();
      this.player.removeEventListener("loadedmetadata", handler);
    };
    this.player.addEventListener("loadedmetadata", handler);
  }

  // Useful for buttons that should play the first track in a section,
  // if you don't want to use a database query to find the track.
  playTrackForSection(section, ifAlreadyPlaying) {
    const index = this.tracklistValue.findIndex((track) => {
      return track.sectionId === section;
    });

    if (index === -1) {
      return;
    }

    this.playTrack(index, ifAlreadyPlaying);
  }

  playTrackOrTogglePlayPauseIfPlaying(index, ifAlreadyPlaying) {
    if (this.trackValue != index) {
      this.playTrack(index, ifAlreadyPlaying);
    } else if (this.pausedValue) {
      this.play();
    } else {
      this.pause();
    }
  }

  // When the current track changes, wait for the track to load, then update the audio player's duration label
  populateMetadata(e) {
    const { duration } = e.target;
    this.durationValue = duration;
  }

  previous() {
    if (this.previousTrackIndex === null) {
      return;
    }

    this.playTrack(this.previousTrackIndex);
    this.visitPageForTrack();
  }

  recordDimensions() {
    const dimensions = {};
    dimensions["audio-player-height"] =
      this.element.getBoundingClientRect().height;
    const cssVariables = Object.entries(dimensions).reduce(
      (css, [key, value]) => {
        return css + `--${key}: ${value}px;`;
      },
      ""
    );
    const css = `:root { ${cssVariables} }`;
    this.stylesTarget.innerHTML = css;
  }

  rewind() {
    this.player.currentTime -= 15;
  }

  showTracklist() {
    this.tracklistVisibleValue = true;
  }

  showTranscript() {
    // TODO: use real breakpoint
    if (window.innerWidth >= 648) {
      this.visitPageForTrack({ unlessOnIndex: false, visitPageForTrack: true });
    } else {
      this.transcriptValue = true;
    }
  }

  togglePlayPause() {
    if (this.pausedValue) {
      return this.play();
    } else {
      return this.pause();
    }
  }

  toggleTracklist() {
    this.tracklistVisibleValue = !this.tracklistVisibleValue;

    // On mobile, if the user closes the tracklist, they are closing the
    // player's full expanded state. Therefore we should also close the
    // transcript, so that when they reopen the player's expanded state,
    // the tracklist isn't still visible.
    if (!this.tracklistVisibleValue) {
      this.transcriptValue = false;
    }
  }

  toggleTranscript() {
    // TODO: use real breakpoint
    if (window.innerWidth >= 648) {
      this.visitPageForTrack({ unlessOnIndex: false, openArtifactPage: true });
    } else {
      this.transcriptValue = !this.transcriptValue;
    }
  }

  updateCurrentTime() {
    const { currentTime } = this.player;
    this.currentTimeValue = currentTime;
  }

  visitPageForTrack({
    track = this.trackValue,
    unlessOnIndex = true,
    openArtifactPage = false,
  } = {}) {
    const { pathname: mainFramePath } = new URL(
      document.getElementById("main").src || window.location.href
    );

    const trackType = this.tracklistValue[track].type;
    let trackPathToUse;
    let turboFrameToUse;
    if (
      openArtifactPage &&
      trackType === "artifact" &&
      window.innerWidth >= 768
    ) {
      trackPathToUse = this.tracklistValue[track].artifactPagePath;
      turboFrameToUse = "tray";
    } else {
      trackPathToUse = this.tracklistValue[track].sectionPagePath;
      turboFrameToUse = "main";
    }

    // If we're not on the index (where the user is presumably in "browse mode"),
    // then navigate to the next track's page. This can be disabled by passing
    // `unlessOnIndex: false`.
    if (unlessOnIndex) {
      if (
        ["/", "/home", "/index-page"].some((path) => mainFramePath === path)
      ) {
        return;
      }
    }

    const [trackPagePath, trackPageHash] = trackPathToUse.split("#");
    if (trackPagePath === mainFramePath) {
      // If we're already on the appropriate page, scroll to the element
      if (trackPageHash) {
        const element = document.getElementById(trackPageHash);
        element?.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // If the page is different, navigate to it. Since hashes are dropped
      // from turbo frame `src`s, the browser will not automatically scroll
      // to the deep link. To fix, we use a custom event that helps us keep
      // track of what the deep link is. This action is handled by the application
      // controller and stored in state. Any time a turbo frame loads,
      // if there is a `deepLink` value present, the application will try to
      // find an element to `scrollIntoView`.
      this.dispatch("before-deep-link", {
        detail: { anchor: trackPageHash },
      });
      Turbo.visit(trackPagePath, { action: "advance", frame: turboFrameToUse });
    }
  }

  /*
  ::::::::::::::::::::::::::::
  :: Changed Value Handlers ::
  ::::::::::::::::::::::::::::
  */

  autoplayNotificationValueChanged(visible) {
    if (visible) {
      this.autoplayNotificationLabelTarget.textContent =
        this.nextTrack?.sectionLabel;
      this.autoplayNotificationTitleTarget.textContent = this.nextTrack?.title;
      this.autoplayNotificationImageTarget.setAttribute(
        "src",
        this.nextTrack?.thumbnail
      );
      this.autoplayNotificationTarget.style.setProperty("display", "block");
      this.autoplayNotificationTarget
        .animate(
          [
            {
              transform: `translateY(100%)`,
            },
            { transform: "translateY(0)" },
          ],
          { duration: 300, easing: "ease-in-out", fill: "forwards" }
        )
        .finished.then(() => {
          this.autoplayNotificationProgressBarTarget
            .animate([{ transform: "scaleX(1)" }, { transform: "scaleX(0)" }], {
              duration: 5000,
              easing: "linear",
              fill: "forwards",
            })
            .finished.then(() => {
              this.autoplayNotificationValue = false;
              this.next();
            });
        });
    } else {
      this.autoplayNotificationTarget
        .animate(
          [{ transform: "translateY(0)" }, { transform: "translateY(100%)" }],
          { duration: 300, easing: "ease-in-out", fill: "forwards" }
        )
        .finished.then(() => {
          this.autoplayNotificationTarget.style.setProperty("display", "none");
        });
    }
  }

  currentTimeValueChanged(currentTime) {
    let formattedTimestamp;
    let progress = 0;
    if (typeof this.currentTimeValue !== "number") {
      formattedTimestamp = "--:--";
    } else {
      formattedTimestamp = timestampToFormattedTime(currentTime);
      progress =
        typeof this.durationValue === "number"
          ? currentTime / this.durationValue
          : 0;
    }

    this.currentTimeTargets.forEach((target) => {
      target.textContent = formattedTimestamp;
    });

    this.progressTargets.forEach((target) => {
      target.style.transform = `scaleX(${1 - progress})`;
    });
  }

  disabledValueChanged(disabled) {
    if (disabled) {
      this.playPauseButtonTargets.forEach((target) => {
        target.setAttribute("disabled", "");
      });
    } else {
      this.playPauseButtonTargets.forEach((target) => {
        target.removeAttribute("disabled");
      });
    }
  }

  durationValueChanged(duration) {
    const formattedTimestamp =
      typeof this.durationValue !== "number"
        ? "--:--"
        : timestampToFormattedTime(duration);
    this.durationTargets.forEach((target) => {
      target.textContent = formattedTimestamp;
    });
  }

  pausedValueChanged(paused) {
    this.playPauseButtonTargets.forEach((target) => {
      target.setAttribute("aria-pressed", !paused);
      target.setAttribute("data-paused", paused);
    });
  }

  posterValueChanged(visible) {
    if (visible) {
      this.posterTarget.style.setProperty("display", "flex");
    } else {
      this.posterTarget.style.setProperty("display", "none");
      this.bookPosterTarget.style.setProperty("display", "none");
    }
  }

  toggleBookPoster(visible) {
    // console.log("audio paused", this.pausedValue, this.posterValue)
    if (!this.pausedValue) {
      this.posterValueChanged(this.pausedValue)
      return
    }
    if (visible) {
      this.posterTarget.style.setProperty("width", "50%");
      this.bookPosterTarget.style.setProperty("display", "flex");
      this.bookPosterTarget.style.setProperty("width", "50%");
    } else {
      this.posterTarget.style.setProperty("width", "100%");
      this.bookPosterTarget.style.setProperty("display", "none");
    }
  }

  skippedSectionNotificationValueChanged(visible) {
    if (visible) {
      this.skippedSectionNotificationTarget.setAttribute(
        "href",
        this.nextSection?.page_path
      );
      this.skippedSectionNotificationLabelTarget.textContent =
        this.nextSection?.label;
      this.skippedSectionNotificationTitleTarget.textContent =
        this.nextSection?.title;
      this.skippedSectionNotificationImageTarget.setAttribute(
        "src",
        this.nextSection?.thumbnail
      );
      this.skippedSectionNotificationTarget.style.setProperty(
        "display",
        "block"
      );
      this.skippedSectionNotificationTarget.animate(
        [{ transform: "translateY(100%)" }, { transform: "translateY(calc(-100% - var(--gutter)))" }],
        { duration: 300, easing: "ease-in-out", fill: "forwards" }
      );
    } else {
      this.skippedSectionNotificationTarget
        .animate(
          [
            { transform: "translateY(calc(-100% - var(--gutter)))" },
            { transform: "translateY(100%)" },
          ],
          { duration: 300, easing: "ease-in-out", fill: "forwards" }
        )
        .finished.then(() => {
          this.skippedSectionNotificationTarget.style.setProperty(
            "display",
            "none"
          );
        });
    }
  }

  trackValueChanged(track, previousTrack) {
    if (!this.currentTrack) {
      return;
    }

    const {
      ordinal,
      sectionPagePath,
      sectionLabel,
      sectionOrdinal,
      source,
      fallbackSource,
      title,
      transcriptPath,
    } = this.currentTrack;

    if (track === null) {
      return;
    }

    // Reset the player timing information. They will be replaced with real values when the media loads and plays.
    this.durationValue = 0;
    this.currentTimeValue = 0;

    // Update the player to use the new audio stream url
    if (source) {
      this.disabledValue = false;
      // if (this.hls && Hls.isSupported()) {
      //   this.hls.loadSource(source);
      // } else if (this.player.canPlayType("application/vnd.apple.mpegurl")) {
      //   this.player.setAttribute("src", source);
      // } else {
      //   this.player.setAttribute("src", fallbackSource);
      // }
      this.player.setAttribute("src", fallbackSource);
    } else {
      this.disabledValue = true;
    }

    // Update the track title
    this.titleTargets.forEach((target) => {
      target.textContent = `${sectionOrdinal}.${ordinal} ${title}`;
      target.setAttribute("href", sectionPagePath);
    });

    // Update the section label
    this.sectionTarget.textContent = sectionLabel;

    // Mark the corresponding item in the tracklist as active
    const previousTracklistItem = this.tracklistItemTargets[previousTrack];
    const tracklistItem = this.tracklistItemTargets[track];
    if (previousTracklistItem) {
      previousTracklistItem.dataset.active = false;
    }
    if (tracklistItem) {
      tracklistItem.dataset.active = true;
    }

    // Update the prev/next buttons
    this.previousTrackTitleTarget.textContent = this.previousTrack?.title;
    this.nextTrackTitleTarget.textContent = this.nextTrack?.title;
    if (this.nextTrackIndex === null) {
      this.nextButtonTargets.forEach((target) => {
        target.setAttribute("disabled", true);
      });
    } else {
      this.nextButtonTargets.forEach((target) => {
        target.removeAttribute("disabled");
      });
    }
    if (this.previousTrackIndex === null) {
      this.previousButtonTargets.forEach((target) => {
        target.setAttribute("disabled", true);
      });
    } else {
      this.previousButtonTargets.forEach((target) => {
        target.removeAttribute("disabled");
      });
    }

    // Update the transcript
    if (transcriptPath) {
      this.transcriptLinkTarget.setAttribute("href", transcriptPath);
      this.transcriptLinkTarget.click();
    }
  }

  tracklistVisibleValueChanged(visible) {
    if (!this.hasTracklistTarget) {
      return;
    }

    if (visible) {
      this.tracklistTarget.style.setProperty("display", "block");
      focusLock.on(this.tracklistTarget);
      this.tracklistButtonTargets.forEach((target) => {
        target.setAttribute("aria-expanded", "true");
      });
    } else {
      this.tracklistTarget.style.setProperty("display", "none");
      focusLock.off(this.tracklistTarget);
      this.tracklistButtonTargets.forEach((target) => {
        target.setAttribute("aria-expanded", "false");
      });
    }

    this.recordDimensions();
  }

  transcriptValueChanged(visible) {
    if (!this.hasTranscriptTarget) {
      return;
    }

    if (visible) {
      const { transcriptPath } = this.currentTrack;
      if (!transcriptPath) {
        return;
      }

      this.transcriptTarget.style.setProperty("display", "block");
      // this.transcriptTarget.animate(
      //   [{ transform: 'translateY(100%)' }, { transform: 'translateY(0%)' }],
      //   { duration: 200, easing: 'ease-in-out', fill: 'forwards' }
      // );
      this.transcriptButtonTarget.setAttribute("aria-expanded", "true");
    } else {
      // this.transcriptTarget.animate(
      //   [{ transform: 'translateY(0%)' }, { transform: 'translateY(100%)' }],
      //   { duration: 200, easing: 'ease-in-out', fill: 'forwards' }
      // ).finished.then(() => {
      this.transcriptTarget.style.setProperty("display", "none");
      // });
      this.transcriptButtonTarget.setAttribute("aria-expanded", "false");
      this.transcriptButtonTarget.focus();
    }
  }
}
