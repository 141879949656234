import { Controller } from "@hotwired/stimulus";
import Flickity from "flickity";

export default class extends Controller {
  static targets = [
    "slideWrapper",
    "slide",
  ];

  static values = {
    index: Number,
    maxIndex: Number,
    slideWidth: Number,
    wrapAround: {
      default: true,
      type: Boolean,
    },
  };

  static captionTarget = "caption";

  connect() {
    this.initializeFlickity();
    console.log("connected")
  }

  disconnect() {
    this.flickity.destroy();
  }

  refresh() {
    this.flickity.resize();
  }

  initializeFlickity() {
    this.flickity = new Flickity(this.slideWrapperTarget, {
      cellAlign: "left",
      draggable: true,
      freeScroll: false,
      prevNextButtons: false,
      pageDots: true,
      imagesLoaded: true,
      wrapAround: true,
      autoPlay: true
    });
  }
}
