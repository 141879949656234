import "@hotwired/turbo";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

// Stimulus Init
window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

// BEGIN MAGIC_MODULE_IMPORT

import * as twoColumnModule from './magic_modules/two_column_module';
import * as imageDisplayModule from './magic_modules/image_display_module';
// END MAGIC_MODULE_IMPORT

// import * as splashPage from "./components/splash_page";
import { svgs } from "./components/svg";
import { scrollToTarget } from "./components/scroll_to_target";

// BEGIN MAGIC_MODULE_INIT

twoColumnModule.init();
imageDisplayModule.init();
// END MAGIC_MODULE_INIT

scrollToTarget.init();
// splashPage.init();
svgs.init();


console.log(`
 _     _       _            _ 
| |   (_)_ __ | | _____  __| |
| |   | | '_ \\| |/ / _ \\/ _  |
| |___| | | | |   <  __/ (_| |
|_____|_|_| |_|_|\\_\\___|\\__,_|
| __ ) _   _                  
|  _ \\| | | |                 
| |_) | |_| |                 
|____/ \\__, |                 
    _  |___/                  
   / \\  (_)_ __               
  / _ \\ | | '__|              
 / ___ \\| | |                 
/_/   \\_\\_|_|                 
                                          
                          
Website by Linked By Air
https://linkedbyair.net

Built on Economy
https://ecnmy.com

Using Stimulus.js, Turbo.js, and Tailwind                  
`);