/* JavaScript for TwoColumnModule */

/*
// Export the class itself
export class TwoColumnModule {
  constructor(element) {
    this.element = element;
  }
}

// Exports an array of all the current instances
export const twoColumnModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the TwoColumnModule on any given page
  document.addEventListener("turbolinks:load", () => {
    twoColumnModules.current = [
      ...document.querySelectorAll(".js-two-column-module"),
    ].map((instance) => new TwoColumnModule(instance));
  });
};
*/

export const init = () => {
  // Place your magic module init code here
};
