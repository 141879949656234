import { Controller } from "@hotwired/stimulus";
import { setCookie } from "../utilities";

export default class extends Controller {
  static targets = [];
  static values = {
    artifact: Number,
    deepLink: String,
    track: Number,
    backdropUrl: String,
  };
  static outlets = [
    "album",
    "audio-player",
    "inline-audio-player",
    "vimeo-player",
    "video-player",
    "track-player",
    "tray",
  ];

  connect() {
    window.application = this;
    this.updateBackdropUrl();
  }

  audioPlayerOutletConnected() {
    this.recordAudioPlayerDimensions();
  }

  deactivateAllAlbums() {
    this.albumOutlets.forEach((album) => {
      album.markAsInactive();
    });
    this.artifactValue = null;
  }

  disableDocumentScroll() {
    document.documentElement.style.setProperty("overflow", "hidden");
  }

  enableDocumentScroll() {
    document.documentElement.style.removeProperty("overflow", "hidden");
  }

  handleAudioPlayerPlay(event) {
    this.pauseAllVideos();
    this.updateTrackPlayers(event);
  }

  pauseAllInlineAudioPlayers() {
    this.inlineAudioPlayerOutlets.forEach((player) => {
      player.pause();
    });
  }

  pauseAllVideos() {
    this.vimeoPlayerOutlets.forEach((player) => {
      player.pause();
    });
  }

  pauseMainPlayer() {
    if (!this.hasAudioPlayerOutlet) {
      return;
    }

    this.audioPlayerOutlet.pause();
  }

  playTrack(event) {
    if (!this.hasAudioPlayerOutlet) {
      return;
    }

    const { track, ifAlreadyPlaying } = event.params;
    this.audioPlayerOutlet.playTrack(track, ifAlreadyPlaying);
  }

  playTrackForSection(event) {
    if (!this.hasAudioPlayerOutlet) {
      return;
    }

    const { section, ifAlreadyPlaying } = event.params;
    this.audioPlayerOutlet.playTrackForSection(section, ifAlreadyPlaying);
  }

  playTrackOrTogglePlayPauseIfPlaying(event) {
    if (!this.hasAudioPlayerOutlet) {
      return;
    }

    const { track, ifAlreadyPlaying } = event.params;
    this.audioPlayerOutlet.playTrackOrTogglePlayPauseIfPlaying(
      track,
      ifAlreadyPlaying
    );
  }

  // Turbo drops hashes from the `src` attribute, and the browser will not
  // automatically scroll to a deep link. To recreate deep linking behavior,
  // this method allows us to store the ID of an element so that we can
  // `scrollIntoView` once a turbo frame loads. This method dovetails with
  // `scrollToDeepLink` to complete the feature: this method keeps track of
  // the element ID, then when a frame loads, `scrollToDeepLink` uses it to
  // scroll the page.
  prepareToScrollToDeepLink(event) {
    let anchor;
    if (event?.detail?.anchor) {
      anchor = event.detail.anchor;
    } else if (event?.params?.anchor) {
      anchor = event.params.anchor;
    } else if (event?.target?.getAttribute("href")) {
      const url = new URL(event.target.getAttribute("href"));
      anchor = url.hash.replace("#", "");
    }
    this.deepLinkValue = anchor;
  }

  recordAudioPlayerDimensions() {
    if (!this.hasAudioPlayerOutlet) {
      return;
    }

    this.audioPlayerOutlet.recordDimensions();
  }

  // See notes in `prepareToScrollToDeepLink`
  //
  // If the element is found, scroll to it.
  // Otherwise, it might be in a turbo frame that hasn't loaded yet,
  // for example in a responsive-content block. In that case, we'll
  // keep the deep link value and try again when the frame loads.
  // The deep link value will be only be unset when the elmement is found.
  // If the application is not aware of any deep link value, it will
  // just scroll to the top of the page.
  scrollToDeepLinkOrResetScroll(event) {
    if (Boolean(this.deepLinkValue)) {
      const element = document.getElementById(this.deepLinkValue);

      if (element) {
        element?.scrollIntoView({ behavior: "smooth" });
        this.deepLinkValue = '';
      }
    } else if (event.params?.anchor || event.detail?.anchor) {
      const element = document.getElementById(event.params?.anchor || event.detail?.anchor);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  setLanguage(event) {
    const { language } = event.params || event.detail;

    if (!language) {
      return;
    }

    setCookie("language", language, 365);
  }

  toggleDocumentScroll() {
    const scrollDisabled =
      document.documentElement.style.getPropertyValue("overflow") === "hidden";
    scrollDisabled ? this.enableDocumentScroll() : this.disableDocumentScroll();
  }

  updateActiveAlbums(event) {
    // Temporarily disabled while we wait for designers to confirm behavior
    return;

    // const artifact = event?.params?.artifact || this.artifactValue;
    // this.albumOutlets.forEach((album) => {
    //   // Weak equality here because `data` will only save strings, but the `params` object will successfully parse a number
    //   if (album.data.get("artifact") == artifact) {
    //     album.markAsActive();
    //   } else {
    //     album.markAsInactive();
    //   }
    // });
    // this.artifactValue = artifact;
  }

  updateTrackPlayers(event) {
    const track = event?.detail?.track || this.trackValue;
    let makeEverythingPaused = false;
    if (
      event.type == "audio-player:pause" ||
      event.type == "audio-player:end" ||
      (this.hasAudioPlayerOutlet && this.audioPlayerOutlet.pausedValue)
    ) {
      makeEverythingPaused = true;
    }

    this.trackPlayerOutlets.forEach((player) => {
      if (makeEverythingPaused || player.data.get("track") != track) {
        player.markAsPaused();
      } else {
        player.markAsPlaying();
      }
    });
    this.trackValue = track;
  }

  visitPageForTrack(event) {
    if (!this.hasAudioPlayerOutlet) {
      return;
    }

    const { track, unlessOnIndex } = event.params;
    this.audioPlayerOutlet.visitPageForTrack({ track, unlessOnIndex });
  }

  updateBackdropUrl() {
    this.backdropUrlValue = window.location.href;
  }

  trayOutletConnected() {
    if (!this.backdropUrlValue) {
      return;
    }

    this.trayOutlet.setBackdropUrl(this.backdropUrlValue);
  }

  updatePoster() {
    const showPoster = window.location.pathname.includes("target=sections-index") || window.location.pathname === "/";
    this.audioPlayerOutlet.toggleBookPoster(showPoster)
  }
}
